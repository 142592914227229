@import "~react-image-gallery/styles/css/image-gallery.css";
:root {
  --main-blue: #4c7ee8;
  --dark-blue: #334660;
  --text-black-1: #2b2b2b;
  --text-black-1-2: #343434;
  --text-black-2: #5b5b5b;
}

body {
  zoom: 90%;
}

/* modal backdrop */
.modal-backdrop {
  width: 100%;
  height: 100%;
}
.modal-fullscreen {
  width: 100%;
}
.modal-fullscreen {
  width: 100%;
}

.static-text img {
  display: block;
  /* background-color: aqua; */
}

body.Arabic .img-rtl, body.Kurdish .img-rtl {
  transform: scaleX(-1);
}

.static-text p:has(> img){
  display: flex;
  justify-content: center;
  align-items: center;
}

strong {
  font-size: 18px;
}
.chatRadio {
  display: none;
}
.chatLabel {
  display: block;
  cursor: pointer;
  margin: 5px;

  /* background-color: blue; */
}
.audioBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.staticConditions img{
  max-width: -webkit-fill-available;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
    display: none;
}

.msgReached {
  background-color: aliceblue;
  border-radius: 20px;
}

/* .rental-price-radio{
  background-color: white;
} */

.rental-price-radio {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.rental-price-radio.active {
  background-color: var(--dark-blue) !important;
  color: white !important;
}

.dynamicSelectClass {
  padding: 1px;
}

.width-full {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .width-full {
    width: 100%;
  }
}

.set {
  padding: 16px;
}

@media screen and (max-width: 600px) {
  .carousel-margin {
    margin-top: 20px;
  }
}

.btn-hover-clear:hover {
  background-color: var(--main-blue) !important;
  color: white !important;
}
@media screen and (max-width: 990px) {
  .clearButton {
    position: absolute !important;
    bottom: 10px;
    /* left: 20px; */
  }
}

/* tabIndex -1 */

/* div[tabindex="-1"] {
  z-index: 99999 !important;
} */

.__indicator-separator {
  display: none;
}
.selectCustom {
  z-index: 999 !important;
  color: unset !important;
}
.__menu {
  width: max-content !important;
  z-index: 99999 !important;
}
/* .selectCustom .__control  {
  height: 45px;
  border: 1px solid #989898 !important;
  background: transparent;
} */
.selectCustom .__dropdown-indicator svg{
  display: none;
}

.selectCustom .__value-container {
  padding-inline: 0px !important;
}
.__group-heading {
  font-size: 16px !important;
}
.__placeholder {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .owl-nav {
    display: none !important;
  }
}

.slides::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  height: 0;
}
.__menu-list::-webkit-scrollbar {
  width: 5px;  /* Remove scrollbar space */
  height: 5px;
}
/* Track */
.__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.__menu-list::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

.thinScroll {
  overflow-y: scroll;
}
.thinScroll::-webkit-scrollbar {
  width: 5px;  /* Remove scrollbar space */
  height: 5px;
}
/* Track */
.thinScroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.thinScroll::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}




.highDiff {
  position: absolute;
  top: -65px;
  right: 0px;
}

.highDiffRtl {
  right: unset !important;
  left: 0px !important;
}

@media screen and (max-width: 900px) {
  .highDiff {
    top: 55px;
  }
}

.rentPriceMargin {
  margin-left: 160px;
}

@media screen and (max-width: 600px) {
  .rentPriceMargin {
    margin-left: 0px;
  }
}

.custom-items {
  position: relative;
  font-family: Arial;
}

.planCard {
  /* border: 1px solid black; */
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px 10px #ebebeb;
}
.planCardDescription {
  background-color: #e5eeff;
  border-radius: 5px;
  padding: 10px 5px !important;
  height: 130px;
}
.planCardPrice {
  margin-top: 10px;
}

@media screen and (max-width: 500px) {
  .cngplanNew {
    position: unset !important;
    display: block;
    width: 100% !important;
    margin-top: 15px;
  }
}



.articleImages img {
    /* max-width: 70% !important; */
    /* border-radius: 10px; */
    padding-top: 30px;
    
  }
  .articleImages p {
    display: flex;
    flex-direction: column;
    
  }

.articleName {
  font-size: 45px !important;
  text-align: center;
  font-weight: 100;
  max-width: 100% !important;
  line-height: 65px !important;
}

.articleImages p {
  width: 90%;
  height: 100%;
  object-fit: cover;
}
.articleBanner {
  max-width: 100% !important;
}
@media screen and (max-width: 500px) {
  .articleImages p {
    width: 100%;
  }
  .articleName {
    font-size: 25px !important;
    text-align: center;
    line-height: 35px !important;
  }
}

.saveShare {
  top: 529px !important;
}
@media screen and (min-width: 1600px) {
  .saveShare {
    top: 545px !important;
  }
}

@media screen and (max-width: 990px) {
  .saveShare > li {
    min-width: unset !important;
  }
  .saveShare > button > li {
    min-width: unset !important;
  }
  .saveShare .loader {
    width: 48px !important;
  }
}
@media screen and (max-width: 575px) {
  .usedNewTabs {
    justify-content: space-around;
  }
}

@media screen and (max-width: 1000px) {
  .topFourMob {
    height: 50px !important;
  }
}
@media screen and (max-width: 1000px) {
  .showMoreBrands {
    height: 40px !important;
  }
}

.searchedBar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  overflow-x: auto;
}
@media screen and (max-width: 500px) {
  .cardSmall {
    padding-bottom: 5px;
    margin-bottom: 0px !important;
    margin-top: 3px !important;
    font-size: 15px !important;
    padding-inline: 0px !important;
  }
}
@media screen and (max-width: 500px) {
  .cardSmallSaved {
    margin-block: -15px !important;
    font-size: 15px !important;
    padding-inline: 0px !important;
  }
}
.compareCarousel {
  flex-wrap: unset !important;
  max-width: 100% !important;
  overflow-x: scroll !important;
}

.toaster_en {
  background-color: #33475f;
  margin: 0px;
  text-align: left;
  font-size: 12px;
}
.toaster_ar {
  background-color: #33475f;
  margin: 0px;
  text-align: right;
  font-size: 12px;
}
.Toastify__toast {
  background-color: #33475f !important;
}
.smallScreenFont {
  font-size: 20px;
}
@media screen and (max-width: 500px) {
  .smallScreenFont {
    font-size: 14px;
  }
}

.imageViewer > div > div > div > img {
  object-fit: contain;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  max-height: unset !important;
}

.IV_ar .react-simple-image-viewer__next {
  left: 0px !important;
  transform: rotate(180deg);
  width: 50px;
  float: left;
}
.IV_ar .react-simple-image-viewer__previous {
  right: 0px !important;
  transform: rotate(180deg);
  width: 50px;
  float: right
}

h6.back > a > img:hover {
  box-shadow: 0px 0px 4px rgb(0 0 0 / 12%);
}
@media screen and (max-width: 575px) {
  h6.back2 {
    margin-inline: 12px !important;
  }
}

.paginationButtonAr > li.next {
  transform: rotate(0deg) !important;
}
.paginationButtonAr > li.previous {
  transform: rotate(180deg) !important;
}

.articleOwl .owl-nav button.owl-next {
  left: unset;
  right: -45px;
}
.articleOwl .owl-nav button.owl-prev {
  right: unset;
  left: -45px;
}
.articleOwlRtl .owl-nav button.owl-next {
  left: -45px;
  right: unset;
  transform: rotate(180deg);
}
.articleOwlRtl .owl-nav button.owl-prev {
  right: -45px;
  left: unset;
  transform: rotate(180deg);
}

body.English .benefits-owl .owl-nav button.owl-next {
  top: 0px !important;
  right: -60px;
}
body.English .benefits-owl .owl-nav button.owl-prev {
  top: 0px !important;
  left: -60px;
}
body.Arabic .benefits-owl .owl-nav button.owl-next, body.Kurdish .benefits-owl .owl-nav button.owl-next {
  top: 0px !important;
  left: -60px;
}
body.Arabic .benefits-owl .owl-nav button.owl-prev, body.Kurdish .benefits-owl .owl-nav button.owl-prev {
  top: 0px !important;
  right: -60px;
}


.markMain:hover {
  border: 1px solid blue !important;
  border-radius: 5px;
}

@media screen and (max-width: 500px) {
  .closed {
    position: unset !important;
    margin-top: 5px;
  }
}
.hed {
  text-align: center !important;
}

.stats {
  width: 60%;
}
.stats > div > div {
  background-color: #F9F9F9;
  height: 90px;
  padding: 5px;
}
.stats > div > div > div {
  padding: 10px;
  border-radius: 2px;
}
/* .stats > div > div > div:hover {
  background-color: #D1E0FF;
} */
@media screen and (max-width: 990px) {
  .stats {
    width: 100% !important;
  }
  .stats > div > div {
    border-radius: 8px !important;
  }
  .stats > div > div > div  {
    border: none !important;
  }
}

.themeButton {
  min-width: 123px;
  height: 45px;
  background-color: var(--main-blue);
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  /* line-height: 45px; */
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  border: none;
}

.invertButtons > div > button.owl-next{
  /* background-color: red !important; */
  right: unset;
  left: 0px;
  transform: rotate(180deg);
}
.invertButtons > div > button.owl-prev{
  /* background-color: red !important; */
  left: unset;
  right: 0px;
  transform: rotate(180deg);
}

/* .hoverUnderline:hover {
  text-decoration: underline;
} */
.heartIcon {
  height: 15px !important;
}
.heartIcon2 {
  height: 22px !important;
}

.priceBoxErrors {
  top: 37px !important;
  left: -10px !important;
  margin-left: 12px !important;
}
.sharePopover .popover {
  max-width: unset !important;
}

@media screen and (max-width: 580px) {
  .sharePopover img, .sharePopover svg {
    height: 30px !important;
    width: 30px !important;
  }
  .sharePopover button, .sharePopover a {
    padding: 5px !important;
  }
  .sharePopover a {
    width: 60px !important;
  }
}

@media screen and (max-width: 580px) {
  .sellbrandIcon {
    width: unset !important;
    height: 170px !important;
  }
}

/* .basic-multi-select {
  width: 175px;
} */
.navTabsSortBy {
  top: 0px !important;
}
@media screen and (max-width: 990px) {
  .navTabsSortBy {
    top: -10px !important;
  }
}

.imgGallOriginal {
  background-color: red;
}
.image-gallery {
  width: 100%;
}

.fuel-new input {
  height: 22px;
  width: 18px;
}
.fuel-new label {
  vertical-align: text-bottom;
}
@media screen and (max-width: 590px){
  .priceClass {
    font-size: 22px !important;
  }
}
.my-radio {
  display: flex;
  font-size: 17px;
  align-items: center;
}
.my-radio-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 2px;
  overflow: hidden;
  border: 1px solid var(--main-blue);
}
.my-radio-icon-inner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.my-radio-active {
  background-color: var(--main-blue);
}

video::-webkit-media-controls-panel {
  background-image: linear-gradient(transparent, #565656) !important;
}

.imgRTL {
  transform: scaleX(-1);
}

@media screen and (max-width: 575px) {
  .movePending {
    top: 40px !important;
    font-size: 11px;
  }
}

#phone-input-custom-wrapper * {
  position: absolute;
}

#phone-input-custom-wrapper label {
  z-index: 99;
  line-height: 25px;
  padding: 2px;
  margin-left: 5px;
}

#phone-input-custom-wrapper input {
  text-indent: 35px;
}


.paymentECheck iframe #root {
  background-color: red !important;
}

.Input-empty {
  background-color: red !important;
}

.customPhoneInput {
  text-indent: 85px;
}
.apexcharts-xaxis {
  direction: ltr;
}
.helperText {
  width: 100%;
  height: 20px;
}
body.English .helperText {
  text-align: left;
}
body.Arabic .helperText, body.Kurdish .helperText {
  text-align: right;
}
@media screen and (max-width: 1200px) {
  .encloseBorder {
    /* border: 1px solid #ccc !important; */
    border-radius: 11px !important;
  }
  .intype {
    /* margin-bottom: 10px !important; */
    padding-left: 50px !important;
  }
  /* body.English .intype button {
    left: 10px !important;
    right: unset;
  }
  body.Arabic .intype button, .Kurdish .intype button {
    left: unset;
    right: 10px !important;
  } */
}

.account-card {
  box-shadow: 0px 4px 37px rgba(136, 147, 184, 0.33);
  border-radius: 11px;
}

@media screen and (max-width: 575px) {
  .no-shadow-below-sm {
    box-shadow: none !important;
  }
  .white-text-below-sm {
    color: white !important;
  }
}

.veriphyPhoneWarning {
  position: absolute;
  top: 60px;
}

@media screen and (min-width: 575px) {
  .veriphyPhoneWarning{
    top: 0px;
  }
}
.disabledButton {
  background-color: #D1E0FF !important;
}

.myTimePicker .MuiPickersLayout-actionBar, .myTimePicker .MuiTypography-overline, .myTimePicker .MuiTimeClock-arrowSwitcher {
  display: none !important;
}

@media screen and (max-width: 500px) {
  .form-check {
    padding-left: 2.5em !important;
  }
}

.modal-header .btn-close {
  margin: 0px;
}

@media screen and (max-width: 575px) {
  .small-title {
    font-size: 22px !important;
    width: 75% !important;
  }
}
@media screen and (max-width: 992px) {
  .small-title {
    width: 80% !important;
  }
}

@media screen and (max-width: 575px) {
  .owl-card-heart{
    position: absolute !important;
    top: 10px;
  }
  body.English .owl-card-heart {
    right: 2px;
  }
  body.Arabic .owl-card-heart, body.Kurdish .owl-card-heart {
    left: 2px;
  }
  .owl-card-heart img {
    height: 15px;
  }

}

@media screen and (min-width: 575px) {
  .app-cards div {
    margin-bottom: 11px;
  }
}
@media screen and (max-width: 575px) {
  .app-cards div {
    margin-bottom: 8px;
  }
}

.owl-item-badge {
  position: absolute;
  top: 8px;
}
body.English .owl-item-badge {
  left: 8px;
}
body.Arabic .owl-item-badge, body.Kurdish .owl-item-badge {
  right: 8px;
}

@media screen and (max-width: 575px) {
  .owl-item-badge {
    top: 5px;
  }
  body.English .owl-item-badge {
    left: 5px;
  }
  body.Arabic .owl-item-badge, body.Kurdish .owl-item-badge {
    right: 5px;
  }
}
.modal-title {
  width: 100%;
}

.searched-section-margin {
  margin-inline: -25px;
}

@media screen and (max-width: 900px) {
  .searched-section-margin {
    margin-inline: 0px;
  }
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

@media (max-width: 580px) {
  .chat_user .form-inline .btn {
    height: 32px !important;
  }  
}

.filtermenu {
  cursor: pointer;
  padding-inline: 3px;
  z-index: 99;
}

.alert-banner-text {
  font-size: 16px;
}

@media (max-width: 580px) {
  .alert-banner-text {
    font-size: 14px;
  }
  
}
.popover-tool {
  max-width: unset !important;
}
.popover-tool .popover-arrow::before {
  border-top: 2px solid var(--main-blue) !important;
  border-left: 2px solid var(--main-blue) !important;
  border-right: 2px solid white !important;
  border-bottom: 2px solid white !important;
  border-radius: 2px;
  border-width: unset;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  background-color: white;
  z-index: 9999;
}
.popover-tool .popover-body {
  border: 2px solid var(--main-blue);
  border-radius: 8px;
  background-color: white;
}
.popover-tool .popover {
  border: 0px;
}

.DatePicker__input {
  font-size: unset;
}

.custom-tabs {
  border-bottom: none;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0px 0px 25px rgb(23 36 44 / 17%);
  border-radius: 8px;
  padding: 7px;
  margin-bottom: 25px;
}
.custom-tabs .nav-item {
  height: 34px;
  border-radius: 8px;
  color: #606060;
  padding: 0;
  line-height: 34px;
  border: none;
  font-size: 15px;
}
.custom-tabs .nav-item.active {
  background: var(--dark-blue) !important;
  color: #fff;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.sidebar-extra {
  z-index: 999;
}
@media (max-width: 580px) {
  .sidebar-extra {
    z-index: 999999 !important;
  }
}
/* 
@media screen and (min-width: 575px) and (max-width: 1200px) {
  .form-control {
    margin-bottom: 35px !important;
  }
} */

.view-modal {
  max-width: 80%;
}

@media screen and (max-width: 575px) {
  .view-modal {
    max-width: 100%;
  }
  .dealer-search-title {
    position: unset !important;
  }
}

.dealer-search-title a {
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .dealer-search-title a {
    position: unset !important;
  }
}

.dealer-search-title div div {
 font-size: 26px;
}
@media screen and (max-width: 575px) {
  .dealer-search-title div div {
    font-size: 18px !important;
  }
}

.faq .accordion-button {
  border-radius: 8px;
  outline: 0;
}
.faq .accordion-button:focus {
  outline: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
.faq .accordion-button:not(.collapsed)  {
  background-color: unset;
  /* color: unset; */
  box-shadow: none;
}
.faq .faq-heading h3{
  font-size: 36px !important;
}

.faq .accordion-button::after {
  margin-right: auto;
  margin-left: unset;
}
body.English .faq .accordion-button::after {
  margin-right: unset;
  margin-left: auto;
}

.navbar-nav .nav-link.active {
  color: var(--main-blue) !important;
  /* box-shadow: 0px 1px 0px 0px var(--main-blue); */
}
.selected {
  color: var(--main-blue) !important;
  /* box-shadow: 0px 1px 0px 0px var(--main-blue); */
}

.content-page h4 {
  margin: unset !important;
}

.usedNewTabs li button{
  height: 30px !important;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100% !important;
}

.usedNewTabs .tab-label {
  font-size: 18px;
  font-weight: 600 !important;
  text-wrap: nowrap;
  transition: ease-in-out .2s;
}
.usedNewTabs svg {
  height: 45px;
  margin-block: -12px;
  transition: ease-in-out .2s;
}
.usedNewTabs .used{
  /* margin-bottom: -7px; */
}
.usedNewTabs .new{
  margin-bottom: -5px;
}
@media screen and (max-width: 768px) {
  .usedNewTabs {
    margin-bottom: 5px !important;
  }
  .usedNewTabs .tab-label {
    font-size: 16px;
  }
  .usedNewTabs li button {
    flex-direction: column-reverse;
    justify-content: center !important;
    height: 55px !important;
    border-radius: 12px 12px 2px 2px !important;
  }
}

.check-car-box {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  margin-top: 16vw;
}

@media screen and (max-width: 768px) {
  .check-car-box {
    margin-top: calc(30px + 20vw);
  }
}

.header-user {
  height: 30px !important;
  width: 30px !important;
  object-fit: cover;
  object-position: center top;
  display: block;
}

.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 600px;
  height: 490px;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(0%);
  height: 100%;
}
@media screen and (min-width: 992px) {
 .slider-section-title .desc {
  min-height: 50px !important;
 }
}
@media screen and (max-width: 575px) {
 .slider-section-title {
    color: white !important;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .slider-section-title .desc {
   min-height: 100px !important;
  }
 .slider-section-title h4 {
    width: 70%;
    margin-top: 10px;
  }
 .slider-section-title p {
    font-size: 13px;
  }
}
@media screen and (max-width: 600px) {
  .arrow-button {
    display: flex !important;
  }
}

.sell-rent-intro .big-img {
  margin-bottom: -110px;
}
@media screen and (max-width: 991px) {
  .sell-rent-intro .big-img {
    margin-bottom: -80px;
  }
}
@media screen and (max-width: 480px) {
  .sell-rent-intro .big-img {
    margin-bottom: -40px;
  }
}

/* @media screen and (max-width: 575px) {
  .new_car h4 {
    margin-top: 90px !important;
  }
}

 */
.banner {
  background-position: right;
}

.check-sell-find {
  margin-block: 60px !important;
}
@media screen and (max-width: 900px) {
  .check-sell-find {
    margin-top: 100px !important;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .check-sell-find {
    margin-top: 190px !important;
    margin-bottom: 60px;
  }
}



/* Container for the plus icon */
.plus-icon {
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out; /* Increased duration for full-circle rotation */
}

/* Horizontal and vertical bars */
.plus-icon::before,
.plus-icon::after {
  content: '';
  position: absolute;
  background-color: white;
  width: 20px; /* Adjust width for balance */
  height: 2px; /* Adjust thickness for balance */
  border-radius: 1px; /* Optional for smoother edges */
  transition: transform 0.6s ease-in-out;
}

/* Vertical bar */
.plus-icon::after {
  transform: rotate(90deg);
}

/* Active state for rotation with a full circle */
.plus-icon.active {
  transform: rotate(135deg); /* 360° + 45° */
}

.car-shadow {
  width: 77%;
  height: 20px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  filter: blur(10px);
  z-index: -1;
  margin-top: -10px;
}

