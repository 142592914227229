.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.pagination li {
  cursor: pointer;
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  user-select: none;
  transition: background-color 0.3s;
}

.pagination li.active {
  background-color: #007bff;
  color: #fff;
}

.pagination li.disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #ccc;
  color: #fff;
}

.pagination li.previous,
.pagination li.next {
  font-weight: bold;
}

.pagination li.break {
  pointer-events: none;
}

.pagination li:hover {
  background-color: #f0f0f0;
  color: #333;
}